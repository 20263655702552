export default {

    //Format picker date in YYY-MM-DD
    dateFormat(date) {
        const dateFormatted = new Date(date);
        return `${dateFormatted.getFullYear()}-${dateFormatted.getMonth() + 1}-${dateFormatted.getDate()}`;
    },

    //Format picker hours in HH:mm
    timeFormat(time) {
        const hourFormatted = new Date(time);
        return `${hourFormatted.getHours()}:${(hourFormatted.getMinutes() < 10 ? '0' : '')}` + `${hourFormatted.getMinutes()}`;
    }
}