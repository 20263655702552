import axios from 'axios';
const TOKEN = 'Bearer fa6fa3935134228467d6c8d1060f5440';

export default {

    async getAutomezzi() {
        const data = new FormData();
        //data.append("limit", "300");
        data.append("offset", "0");
        //data.append("orderby", "customers_company");
        //data.append("orderdir", "asc");

        const response = await axios.post('/search/automezzi', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response.data.data;
    }

}