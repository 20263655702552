<template>
  <ion-page>
    <!--<ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Dettaglio impianto</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" class="ion-padding">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Dettaglio impianto</ion-title>
        </ion-toolbar>
      </ion-header>-->
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Dettaglio impianto</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
          </ion-buttons>
          <ion-title size="large">Dettaglio impianto</ion-title>
        </ion-toolbar>
      </ion-header>

      <div v-if="loading" class="loading_spinner">
        <ion-spinner name="crescent" color="primary"></ion-spinner>
      </div>
      <div v-else>
        <div class="impianto">
          <ion-card>
            <ion-card-header class="pb-0 bg-primary">
              <ion-card-title>
                <ion-text color="light">
                  <p class="card_title">
                    Impianto {{ impianto.projects_codice }}
                  </p>
                </ion-text>
              </ion-card-title>
            </ion-card-header>

            <ion-card-content>
              <ion-list>
                <ion-item class="ion-no-padding">
                  <ion-label color="primary">Tipologia</ion-label>
                  <ion-text>
                    <p class="base_text">
                      {{ impianto.projects_type_value || " - " }}
                    </p>
                  </ion-text>
                </ion-item>
                <ion-item class="ion-no-padding">
                  <ion-label color="primary"> Indirizzo </ion-label>
                  <ion-text>
                    <p class="base_text" style="text-align: right">
                      {{ impianto.projects_impianto_indirizzo }}<br />
                      {{ impianto.comuni_label }}
                    </p>
                  </ion-text>
                </ion-item>
                <ion-item class="ion-no-padding">
                  <ion-label color="primary">Stato</ion-label>
                  <ion-text>
                    <p class="base_text">
                      <ion-badge
                        :color="statoImpianto(impianto.projects_status_id)"
                      >
                        {{ impianto.projects_status_value }}
                      </ion-badge>
                    </p>
                  </ion-text>
                </ion-item>
                <ion-item class="ion-no-padding">
                  <ion-label color="primary"> Capo commessa </ion-label>
                  <ion-text>
                    <p class="base_text">
                      {{ showCapoCommessa(impianto) }}
                    </p>
                  </ion-text>
                </ion-item>
                <ion-item class="ion-no-padding">
                  <ion-label color="primary"> Data contratto </ion-label>
                  <ion-text>
                    <p class="base_text">
                      {{ dateFormat(impianto.projects_data_contratto) }}
                    </p>
                  </ion-text>
                </ion-item>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </div>

        <div class="tabbed_interface">
          <ion-segment value="menu">
            <ion-segment-button value="libretto" @click="handleShowLibretto">
              <ion-label>Libretto</ion-label>
            </ion-segment-button>
            <ion-segment-button
              value="lavorazioni"
              @click="handleShowLavorazioni"
            >
              <ion-label>Lavorazioni</ion-label>
            </ion-segment-button>
            <ion-segment-button value="materiali" @click="handleShowMateriali">
              <ion-label>Materiali</ion-label>
            </ion-segment-button>
          </ion-segment>
        </div>

        <div class="libretto" v-show="mostraLibretto">
          <ion-card>
            <ion-card-content>
              <div
                v-if="libretto.length == 0"
                class="ion-text-center ion-margin-top"
              >
                <ion-text color="danger" class="font-bold">
                  Il libretto impianto è vuoto
                </ion-text>
              </div>
              <div v-else>
                <ion-list>
                  <ion-item
                    class="ion-no-padding"
                    v-for="record in libretto"
                    :key="record.libretto_impinato_id"
                  >
                    <ion-label>
                      <ion-text color="primary">
                        <strong>{{
                          dateFormat(record.libretto_impianto_data_attivita)
                        }}</strong>
                      </ion-text>
                      <!-- <h3>
                        {{ materiale.libretto_impianto_tecnico_attivita }}
                      </h3> -->
                      <div class="base_text">
                        {{ record.libretto_impianto_descrizione_attivita }}
                      </div>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </div>
            </ion-card-content>
          </ion-card>
        </div>

        <div class="lavorazioni" v-show="mostraLavorazioni">
          <ion-card>
            <ion-card-content>
              <div v-if="!lavorazioni" class="ion-text-center ion-padding-top">
                <ion-text color="danger" class="font-bold">
                  L'impianto non ha lavorazioni associate
                </ion-text>
              </div>
              <div v-else>
                <ion-list>
                  <ion-item
                    class="ion-no-padding"
                    v-for="(lavorazione, index) in lavorazioni"
                    :key="index"
                  >
                    <ion-text>
                      <p class="base_text">
                        {{ lavorazione }}
                      </p>
                    </ion-text>
                  </ion-item>
                </ion-list>
              </div>
            </ion-card-content>
          </ion-card>
        </div>

        <div class="materiali" v-show="mostraMateriali">
          <ion-card>
            <ion-card-content>
              <div
                v-if="materiali.length == 0"
                class="ion-text-center ion-padding-top"
              >
                <ion-text color="danger" class="font-bold">
                  L'impianto non ha materiali associati
                </ion-text>
              </div>
              <div v-else>
                <ion-list>
                  <ion-item
                    class="ion-no-padding"
                    v-for="materiale in materiali"
                    :key="materiale.projects_billing_items_id"
                  >
                    <ion-label>
                      <ion-text color="primary">{{
                        materiale.items_marca_value
                      }}</ion-text>
                      <h3>{{ materiale.items_name }}</h3>
                      <p class="base_text">
                        Quantità:
                        {{ materiale.projects_billing_items_quantity }} - Stato:
                        {{ materiale.projects_billing_items_stato_value }}
                      </p>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </div>
            </ion-card-content>
          </ion-card>
        </div>
      </div>

      <ion-fab horizontal="end" vertical="bottom" slot="fixed">
        <ion-fab-button color="success" @click="openModal">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonSpinner,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonBadge,
  IonSegment,
  IonSegmentButton,
  IonFab,
  IonFabButton,
  IonIcon,
  /*IonFab,
  IonFabList,
  IonFabButton,
  IonIcon,*/
  modalController,
} from "@ionic/vue";
import { add, menu, construct } from "ionicons/icons";

import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import apiCommesse from "../services/commesse";
import { openToast } from "../services/toast";
import { getCustomer } from "../services/localbase";

import Modal from "../components/Modal.vue";

import moment from "moment";

export default {
  name: "CommessaDetail",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonSpinner,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonBadge,
    IonSegment,
    IonSegmentButton,
    IonFab,
    IonFabButton,
    IonIcon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const idCommessa = route.params.id_commessa;
    const idCliente = route.params.id;

    const loading = ref(false);
    const loadingInfoCommessa = ref(false);

    const impianto = ref([]);
    const lavorazioni = ref([]);
    const materiali = ref([]);
    const libretto = ref([]);
    //flag per visualizzare i dati nelle tab
    const mostraLibretto = ref(true);
    const mostraLavorazioni = ref(false);
    const mostraMateriali = ref(false);

    const cliente = ref([]);

    /**
     *
     * Get selected customer data to use in modal info
     *
     */
    async function getCliente(idCliente) {
      const customer = await getCustomer(idCliente);
      //console.log("dati del cliente da locale");
      //console.log(customer);
      cliente.value = customer;
      //return customer;
    }
    getCliente(idCliente);

    /**
     *
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     *
     */
    function dateFormat(date) {
      if (date) {
        if (moment.isDate(new Date(date))) {
          return moment(date).format("DD/MM/YYYY");
        } else {
          return "-";
        }
      } else {
        return "-";
      }

      /*const dateFormatted = new Date(date);
      return `${dateFormatted.getDate()}/${
        dateFormatted.getMonth() + 1
      }/${dateFormatted.getFullYear()}`;*/
    }

    function showCapoCommessa(impianto) {
      if (impianto.users_first_name && impianto.users_last_name) {
        return `${impianto.users_first_name} ${impianto.users_last_name}`;
      } else {
        return " - ";
      }
    }

    /**
     *
     * Set correct background for impianto status
     *
     */
    const statoImpianto = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 9) {
          //contrattualizzato
          className = "warning";
        } else if (statusId == 10) {
          className = "danger";
        } else if (statusId == 11) {
          className = "secondary";
        } else if (statusId == 12) {
          className = "dark";
        } else {
          className = "success";
        }
        return className;
      };
    });

    /**
     *
     * Set correct card to show/hide on tabbed interface
     *
     */
    function handleShowLibretto() {
      mostraLavorazioni.value = false;
      mostraMateriali.value = false;
      mostraLibretto.value = true;
    }
    function handleShowLavorazioni() {
      mostraMateriali.value = false;
      mostraLibretto.value = false;
      mostraLavorazioni.value = true;
    }
    function handleShowMateriali() {
      mostraLavorazioni.value = false;
      mostraLibretto.value = false;
      mostraMateriali.value = true;
    }
    handleShowLibretto();

    /**
     *
     * Open modal to create new intervento
     *
     */
    async function openModal() {
      const modal = await modalController.create({
        component: Modal, //Component to render inside ionic modal
        componentProps: {
          cliente: cliente.value,
          impianti: impianto.value,
        },
      });
      modal.onDidDismiss().then((detail) => {
        //console.log(detail.data);
        if (detail.data != undefined) {
          //insert new record and update localstorage
          //interventi.value.push(detail.data);
          //console.log(detail.data);
          //const interventiTotali = JSON.stringify(interventi.value);
          //devo settare il vecchio array interventi + il dato nuovo alla fine
          // ...
          //localStorage.setItem("interventi", interventiTotali);
          openToast("Nuovo intervento inserito", "success");
          router.push(`/tabs/tab2/${detail.data.interventi_id}`);
        }
      });
      return modal.present();
    }

    onMounted(() => {
      loading.value = true;
      loadingInfoCommessa.value = true;
      /* GET INFO COMMESSA */
      apiCommesse
        .getCommessaDetail(idCommessa)
        .then((response) => {
          //console.log(response);
          impianto.value = response;
          lavorazioni.value = response.projects_lavorazioni;
        })
        .catch((error) => {
          error.value = true;
          openToast("Errore durante la richiesta dei dati", "danger");
          console.error(error);
        })
        .finally(() => {
          loading.value = false;
          //console.log("fine getCommessaDetail");
        });

      /* GET LIBRETTO COMMESSA */
      apiCommesse
        .getCommessaLibretto(idCommessa)
        .then((response) => {
          //console.log(response);
          libretto.value = response;
        })
        .catch((error) => {
          error.value = true;
          openToast("Errore durante la richiesta del libretto", "danger");
          console.error(error);
        })
        .finally(() => {
          loadingInfoCommessa.value = false;
          //console.log("fine getCommessaLibretto");
        });

      /* GET MATERIALE COMMESSA */
      apiCommesse
        .getMaterialeCommessa(idCommessa)
        .then((response) => {
          //console.log(response);
          materiali.value = response;
          //console.log(materiali.value);
        })
        .catch((error) => {
          error.value = true;
          openToast("Errore durante la richiesta dei materiali", "danger");
          //console.error(error);
        })
        .finally(() => {
          loadingInfoCommessa.value = false;
          //console.log("fine getMaterialecommessa");
        });
    });

    return {
      loading,
      dateFormat,
      showCapoCommessa,
      impianto,
      libretto,
      lavorazioni,
      materiali,
      statoImpianto,
      //flag gestione visualizzazione tab
      handleShowLibretto,
      handleShowLavorazioni,
      handleShowMateriali,
      mostraLibretto,
      mostraLavorazioni,
      mostraMateriali,
      //Modale nuovo intervento
      add,
      menu,
      construct,
      openModal,
    };
  },
};
</script>

<style scoped>
.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabbed_interface {
  padding: 0 10px;
}

.impianto,
.libretto,
.lavorazioni,
.materiali {
  margin-bottom: 20px;
}
.impianto_codice {
  margin-bottom: 0;
}

.base_text {
  font-size: 14px;
}
.pb-0 {
  padding-bottom: 0;
}
.bg-primary {
  background: var(--ion-color-primary, #3880ff) !important;
  padding: 8px;
  color: #ffffff;
}

.card_title {
  margin: 0;
  padding: 0;
  font-size: 20px;
}

ion-item {
  font-size: 14px;
}
ion-label {
  margin: 0;
}

.font-bold {
  font-weight: 600;
}
</style>